import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect } from 'react';

import ErrorPage from 'components/error-page';
import { Layout } from 'components/layout';
import acceptedTalks from 'components/pages/dubbing/slug/accepted-talks.json';
import { TalkPageContextProvider } from 'components/pages/talks/slug/talk-page-context';
import useVideoPlayerStore from 'components/video-player/store';

import { useNavUserInformationQuery } from 'api';
import { host } from 'f';
import { getTranscript } from 'lib/get-transcript';
import type { TalkPageActionProps } from 'lib/pages/talks/slug/[...slug].props';
import { getProps } from 'lib/pages/talks/slug/getProps';
import { useFeatureFlags } from 'lib/pages/talks/slug/store';

import { sendErrorToServices } from 'lib/logging';
import { GetServerSidePropsContext } from 'next';
import dynamic from 'next/dynamic';
import shallow from 'zustand/shallow';
import authorizedUsers from '../../../authorized-users.json';

const TalkPage = dynamic(() => import('components/pages/talks/slug'));

export default function Talk({
  action,
  videoData,
  transcriptData,
  shortenedUrl,
  commentsEnabled,
  commentsLoggedInOnly
}: TalkPageActionProps): React.ReactNode {
  const router = useRouter();
  const { data } = useNavUserInformationQuery();

  const { updateFlag } = useFeatureFlags();

  const { onEmbed } = useVideoPlayerStore(
    state => ({
      onEmbed: state.onEmbed
    }),
    shallow
  );

  useEffect(() => {
    const isAuthorizedUser =
      Boolean(data?.viewer?.primaryEmailAddress) &&
      authorizedUsers.includes(data?.viewer?.primaryEmailAddress as string);

    updateFlag('newTalkPageExperience', isAuthorizedUser);
    onEmbed(false);
  }, [data, updateFlag, onEmbed]);

  if (
    action?.type === 'client error' ||
    action?.type === 'redacted' ||
    action?.type === 'not found' ||
    action?.type === 'server error'
  ) {
    return <ErrorPage statusCode={action.code} />;
  }

  if (action?.type === 'redirect') {
    router.replace(action.location);
    return null;
  }

  return (
    <Layout>
      <Script
        src="https://www.youtube.com/iframe_api"
        strategy="lazyOnload"
        // This attribute is used to prevent OneTrust from auto-blocking the
        // scripts generated by Next.js
        // the `ot` is short for OneTrust
        data-ot-ignore
      />
      <TalkPageContextProvider>
        <TalkPage
          videoData={videoData || null}
          transcript={transcriptData || undefined}
          shortenedUrl={shortenedUrl || ''}
          commentsEnabled={Boolean(commentsEnabled)}
          commentsLoggedInOnly={Boolean(commentsLoggedInOnly)}
        />
      </TalkPageContextProvider>
    </Layout>
  );
}

async function getShortURL(url: string): Promise<string> {
  const formData = new URLSearchParams({ url });

  const response = await fetch(process.env.URL_SHORTENER_URL as string, {
    method: 'POST',
    body: formData.toString(),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });

  if (!response.ok) {
    sendErrorToServices(
      'Failed to get the short URL',
      `Status: ${response.status}, Url: ${url}`
    );
  }

  const location = response.headers.get('location');

  if (!location) {
    sendErrorToServices(
      'Failed to get the short URL from the location header',
      `Status: ${response.status}, StatusText: ${response.statusText}, Url: ${url}`
    );
  }

  return location as string;
}

export async function getServerSideProps(ctx: GetServerSidePropsContext) {
  const { res, query } = ctx;
  res.setHeader(
    'Cache-Control',
    'no-transform, public, s-maxage=180, stale-while-revalidate=60'
  );

  const path = res.req.url;
  const props = await getProps({ query, asPath: path });

  // Handle server error first
  if (props.action?.type === 'server error') {
    res.statusCode = props.action.code;
    return {
      props: {
        action: props.action,
        errors: props.errors || null,
        videoData: null,
        transcriptData: null,
        shortenedUrl: '',
        commentsEnabled: false,
        commentsLoggedInOnly: false
      }
    };
  }

  const slug = props?.videoData?.slug;

  const url = new URL(`/talks/${query.slug?.[0]}`, host);
  Object.entries(query)
    .filter(([key]) => key !== 'slug')
    .forEach(([key, value]) => {
      url.searchParams.append(key, value as string);
    });

  const language = props.videoData?.language || 'en';
  let transcript = null;

  try {
    if (slug) {
      transcript = await getTranscript(slug, language);
    }
  } catch (error) {
    // Log transcript fetch error but don't fail the page load
    sendErrorToServices('Failed to fetch transcript', error, {
      component: 'TalkPage Transcript',
      context: { slug, language }
    });
  }

  // Fix URL construction to properly handle existing query parameters
  const separator = url.href.includes('?') ? '&' : '?';
  const urlToShorten = `${url.href}${separator}utm_campaign=tedspread&utm_medium=referral&utm_source=tedcomshare`;
  let shortenedUrl = '';

  try {
    shortenedUrl = await getShortURL(urlToShorten);
  } catch (error) {
    // Log URL shortening error but don't fail the page load
    sendErrorToServices('Failed to shorten URL', error, {
      component: 'TalkPage URL Shortener',
      context: { urlToShorten }
    });
  }

  const acceptedTalk = acceptedTalks.find(talk => talk.slug === slug);
  if (acceptedTalk) {
    // Redirect to dubbing page if the talk is accepted
    // More information about this can be found at https://github.com/vercel/next.js/discussions/14890
    return {
      redirect: {
        permanent: false,
        destination: `/dubbing/${acceptedTalk.slug}`
      }
    };
  }

  if (props.action?.type === 'redirect') {
    res.writeHead(props.action.code, { Location: props.action.location });
    res.end();
    return { props: {} };
  }

  if (props.action?.code) {
    res.statusCode = props.action.code;
  }

  return {
    props: {
      shortenedUrl: shortenedUrl || '',
      action: props.action || null,
      videoData: props.videoData || null,
      transcriptData: transcript || null,
      commentsEnabled: Boolean(props?.videoData?.commentsEnabled),
      commentsLoggedInOnly: Boolean(props?.videoData?.commentsLoggedInOnly)
    }
  };
}
