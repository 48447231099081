import { create } from 'zustand';

type FeatureFlagsState = {
  newTalkPageExperience: boolean;
};

type FeatureFlagsActions = {
  updateFlag: (flag: keyof FeatureFlagsState, value: boolean) => void;
};

export const [useFeatureFlags] = create<
  FeatureFlagsState & FeatureFlagsActions
>(set => ({
  newTalkPageExperience: false,
  updateFlag: (flag, value) => set(prev => ({ ...prev, [flag]: value }))
}));

export type { FeatureFlagsState, FeatureFlagsActions };
